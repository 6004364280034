<template>
    <el-dialog v-dialog-drag title="客户关系映射" width="500px" :visible="visible" :close-on-click-modal="false"
        @close="visible = false">
        <el-form label-width="120px" label-position="right" :model="bindInfo" v-if="isBind == true">
            <el-form-item label="主客户：">
                <span style="font-size: 20px;"> {{ bindInfo.main.Name }}{{ bindInfo.main.QQ ? '(' + bindInfo.main.QQ +
        ')' : '' }}</span>
                <span style="color: #409EFF;font-size: 12px;margin-left: 10px;"
                    v-if="sender.PurchaserID == bindInfo.main.PurchaserID">当前账号</span>
            </el-form-item>
            <el-form-item v-for="(child, index) in bindInfo.childs" :key="index" :label="'子客户' + (index + 1) + '：'">
                {{ child.Name }}{{ child.QQ ? '(' + child.QQ + ')' : '' }}
                <span style="color: #409EFF;font-size: 12px;margin-left: 10px;"
                    v-if="sender.PurchaserID == child.PurchaserID">当前账号<el-button @click="unBind" type="primary"
                        size="mini" style="margin-left: 10px;">解绑</el-button></span><br />
                <span style="color: #7f7f7f;font-size: 12px;">映射时间：{{ child.BindTime }}</span>
            </el-form-item>
        </el-form>
        <el-form v-else label-width="120px" label-position="right" :model="editBindInfo">
            <el-form-item label="当前客户：">
                {{ sender.Name }}{{ sender.SenderUid ? '(' + sender.SenderUid + ')' : '' }}
            </el-form-item>
            <el-form-item label="映射客户：">
                <el-autocomplete v-model="editBindInfo.selectPurchaser.PurchaserName"
                    :fetch-suggestions="searchPurchaser" placeholder="请输入客户名称" value-key="PurchaserName"
                    :trigger-on-focus="false" @select="handleSelectPurchaser"></el-autocomplete>
            </el-form-item>
            <el-form-item>
                <span style="color: red;">温馨提示：映射成功后，当前客户的配置失效</span>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-if="!isBind">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button type="primary" size="small" @click="saveBindPurchaser">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { submit } from '@/api/base';
export default {
    name: "StallConfig",
    data() {
        return {
            visible: false,
            isBind: false,
            sender: {},
            bindInfo: {
                main: {},
                childs: []
            },
            editBindInfo: {
                purchasers: [],
                selectPurchaser: {}
            },
        }
    },
    methods: {
        async show(sender) {
            this.editBindInfo.selectPurchaser = {};
            this.sender = sender;
            this.bindInfo = { main: {}, childs: [] };
            if (!sender.ParentPurchaserID) {
                this.isBind = false;
            }
            else {
                this.isBind = true;
                await this.getBindInfo(sender.ParentPurchaserID);
            }
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
        async searchPurchaser(queryString, cb) {
            const { data } = await submit("/api/stall/getPurchaserList", {
                searchValue: queryString, ToPage: false
            });
            data.forEach(d => {
                d.PurchaserName = d.Name + (d.QQ ? '(' + d.QQ + ')' : '');
            });
            let result = data.filter(t => t.PurchaserID != this.sender.PurchaserID);
            // 调用 callback 返回建议列表的数据
            cb(result);
        },
        handleSelectPurchaser(item) {
            this.editBindInfo.selectPurchaser = item;
        },
        async saveBindPurchaser() {
            this.$confirm(`映射关系一旦成立后，历史的数据无法进行合并，但是后续的数据会自动合并为映射客户，且此操作无法撤回!`, "确定映射", {
                confirmButtonText: "确定映射",
                cancelButtonText: "取消",
                type: "warning",
            }).then(async () => {
                await submit("/api/stall/bindPurchaser", {
                    purchaserID: this.editBindInfo.selectPurchaser.PurchaserID,
                    sender: this.sender
                });
                this.$message({
                    type: "success",
                    message: "关联成功!",
                });
                this.$emit("handleSearch");
                this.visible = false;
            });
        },
        async unBind() {
            this.$confirm(`已提交数据不可恢复，确定解绑映射吗？`, "解绑映射", {
                confirmButtonText: "确定解绑",
                cancelButtonText: "取消",
                type: "warning",
            }).then(async () => {
                await submit("/api/stall/unBindPurchaser", this.sender);
                this.$message({
                    type: "success",
                    message: "解绑成功!",
                });
                this.$emit("handleSearch");
                this.visible = false;
            });
        },
        async getBindInfo(purchaserID) {
            const { data } = await submit("/api/stall/getPurchaserChilds", {
                purchaserID: purchaserID
            });
            this.bindInfo = data;
        }
    }
}
</script>